<template>
  <div class="vd_sidebar">
    <el-menu class="vd_sidebar-el-menu" default-active="/SHBark/SalesContract" unique-opened router>
      <el-menu-item class="menuText" index="/SHBark/SalesContract">Sales Contract</el-menu-item>
      <el-menu-item class="menuText" index="/SHBark/Procurement">Procurement</el-menu-item>
      <el-menu-item class="menuText" index="/SHBark/Shipping">Shipping</el-menu-item>
      <el-menu-item class="menuText" index="/SHBark/WeeklyPOReport">Weekly PO Report</el-menu-item>
    </el-menu>
  </div>
</template>

<script>
export default {
  data() {
    return {
      home: '/home'
    };
  },
  created() {}
};
</script>

<style scoped>
.vd_sidebar {
  display: block;
  position: absolute;
  left: 0;
  top: 75px;
  bottom: 0;
  overflow-y: scroll;
}
.vd_sidebar::-webkit-scrollbar {
  width: 0;
}
.vd_sidebar-el-menu:not(.el-menu--collapse) {
  width: 200px;
}
.vd_sidebar > ul {
  height: 100%;
}
.menuText {
  font-size: 16px;
}
::v-deep .el-menu-item.is-active {
  background-color: #409eff !important; /* 激活项背景色 */
  color: #fff; /* 激活项字体颜色 */
}
</style>
